import React from "react"
import { Col, Row } from "reactstrap"
import { InternalLink, Project, TitleContent } from "./parts"
import { Interests } from "./interests"

export const AboutMe = () => (
  <div>
    <Project heading="Elayabharath Elango" />
    <Interests />
    <TitleContent title="Work">
      <div className="section-bottom">
        <InternalLink to="/assembled" label="Assembled" />
        <p style={{ marginBottom: 0 }}>Front-end developer, UX designer</p>
        <p style={{ opacity: 0.5 }}>
          Oct 2020 - Present [2+ years]
          <br />
          San Francisco, USA / Remote
        </p>
      </div>
      <div className="section-bottom">
        <InternalLink to="/katikati" label="Lark Systems" />
        <p style={{ marginBottom: 0 }}>
          Front-end developer, UX designer (Open Source)
        </p>
        <p style={{ opacity: 0.5 }}>
          Jun 2020 - Present [2+ years]
          <br />
          Cambridge, UK / Remote
        </p>
      </div>
      <div className="section-bottom">
        <h4>Independent consulting</h4>
        <p style={{ marginBottom: 0 }}>Full-stack developer, UX designer </p>
        <p style={{ opacity: 0.5 }}>
          Jun 2018 / Sep 2020 [2 years 2 months]
          <br />
          Singapore
        </p>
      </div>
      <div className="section-bottom">
        <InternalLink to="/semantics3" label="Semantics3" />
        <p style={{ marginBottom: 0 }}>Front-end developer, UX designer </p>
        <p style={{ opacity: 0.5 }}>
          Jun 2015 - May 2018 [3 years]
          <br />
          Bengaluru, India
        </p>
      </div>
      <div>
        <InternalLink to="/autodesk-dynamo" label="Autodesk Dynamo" />
        <p style={{ marginBottom: 0 }}>UX designer </p>
        <p style={{ opacity: 0.5 }}>
          May 2012 - May 2015 [3 years]
          <br />
          Singapore
        </p>
      </div>
    </TitleContent>
    <TitleContent title="Education">
      <h4>National University of Singapore</h4>
      <p style={{ marginBottom: 0 }}>Bachelor of Engineering</p>
      <p style={{ marginBottom: 0 }}>Major in Mechanical Eng.</p>
      <p style={{ marginBottom: 0 }}>Specialised in Aeronautical Eng.</p>
      <p style={{ opacity: 0.5 }}>2008 - 2012 / Singapore</p>
    </TitleContent>
    <TitleContent title="Hackathons">
      <InternalLink to="/hackathons" label="View" />
    </TitleContent>
    <TitleContent title="Resume">
      <InternalLink label="Download" to="/resume" />
    </TitleContent>
    <div className="section-bottom">
      <Row>
        {[1, 2, 3, 4].map(i => (
          <Col lg={3} md={6} xs={6} sm={6} key={i}>
            <img
              src={`/images/eb/eb-${i}.jpg`}
              alt="people"
              style={{ marginBottom: 30 }}
            />
          </Col>
        ))}
      </Row>
    </div>
  </div>
)
