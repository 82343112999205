import React from "react"
import { Col, Row } from "reactstrap"
import { interests } from "../data"
import { InternalLink, ExternalLink } from "./parts"

export const Interests = () => (
  <div className="section-bottom">
    <Row>
      {interests.map(i => (
        <Col lg={3} md={6} xs={12} sm={12} key={i.url}>
          {i.isExternal ? (
            <ExternalLink
              href={i.url}
              label={
                <span>
                  {i.icon} {i.title}
                </span>
              }
              block
            />
          ) : (
            <InternalLink
              to={i.url}
              label={
                <span>
                  {i.icon} {i.title}
                </span>
              }
              block
            />
          )}
        </Col>
      ))}
    </Row>
  </div>
)
